import {
	MessageDecoder,
	MessageEncoder
} from '@livekit/components-core';
import { isEqualTrackRef, isTrackReference, isWeb, log } from '@livekit/components-core';
import { RoomEvent, Track } from 'livekit-client';
import * as React from 'react';
import { MessageFormatter } from '@livekit/components-react';
import {
	CarouselLayout,
	ConnectionStateToast,
	FocusLayout,
	FocusLayoutContainer,
	GridLayout,
	LayoutContextProvider,
	ParticipantTile,
	RoomAudioRenderer,
	ControlBar, Chat
} from '@livekit/components-react';
import { useCreateLayoutContext } from '@livekit/components-react';
import { usePinnedTracks, useTracks } from '@livekit/components-react';
//import { warnAboutMissingStyles } from '@livekit/components-core';


export function LiveLaunch({
	chatMessageFormatter,
	chatMessageDecoder,
	chatMessageEncoder,
	SettingsComponent,
	...props
}) {
	const [widgetState, setWidgetState] = React.useState({
		showChat: false,
		unreadMessages: 0,
		showSettings: false,
	});
	const lastAutoFocusedScreenShareTrack = React.useRef; // < TrackReferenceOrPlaceholder | (null);

	const tracks = useTracks(
		[
			{ source: Track.Source.Camera, withPlaceholder: true },
			{ source: Track.Source.ScreenShare, withPlaceholder: false },
		],
		{ updateOnlyOn: [RoomEvent.ActiveSpeakersChanged], onlySubscribed: false },
	);

	const widgetUpdate = (state) => {
		log.debug('updating widget state', state);
		setWidgetState(state);
	};

	const layoutContext = useCreateLayoutContext();

	const screenShareTracks = tracks
		.filter(isTrackReference)
		.filter((track) => track.publication.source === Track.Source.ScreenShare);

	const focusTrack = usePinnedTracks(layoutContext)?.[0];
	const carouselTracks = tracks.filter((track) => !isEqualTrackRef(track, focusTrack));

	//function useWarnAboutMissingStyles() {
	//	React.useEffect(() => {
	//		warnAboutMissingStyles();
	//	}, []);
	//}

	React.useEffect(() => {
		// If screen share tracks are published, and no pin is set explicitly, auto set the screen share.
		if (
			screenShareTracks.some((track) => track.publication.isSubscribed) &&
			lastAutoFocusedScreenShareTrack.current === null
		) {
			log.debug('Auto set screen share focus:', { newScreenShareTrack: screenShareTracks[0] });
			layoutContext.pin.dispatch?.({ msg: 'set_pin', trackReference: screenShareTracks[0] });
			lastAutoFocusedScreenShareTrack.current = screenShareTracks[0];
		} else if (
			lastAutoFocusedScreenShareTrack.current &&
			!screenShareTracks.some(
				(track) =>
					track.publication.trackSid ===
					lastAutoFocusedScreenShareTrack.current?.publication?.trackSid,
			)
		) {
			log.debug('Auto clearing screen share focus.');
			layoutContext.pin.dispatch?.({ msg: 'clear_pin' });
			lastAutoFocusedScreenShareTrack.current = null;
		}
		if (focusTrack && !isTrackReference(focusTrack)) {
			const updatedFocusTrack = tracks.find(
				(tr) =>
					tr.participant.identity === focusTrack.participant.identity &&
					tr.source === focusTrack.source,
			);
			if (updatedFocusTrack !== focusTrack && isTrackReference(updatedFocusTrack)) {
				layoutContext.pin.dispatch?.({ msg: 'set_pin', trackReference: updatedFocusTrack });
			}
		}
	}, [
		screenShareTracks
			.map((ref) => `${ref.publication.trackSid}_${ref.publication.isSubscribed}`)
			.join(),
		focusTrack?.publication?.trackSid,
		tracks,
	]);

	console.log(tracks);
	//useWarnAboutMissingStyles();

	return (
		<div className="lk-video-conference" {...props}>
			{isWeb() && (
				<LayoutContextProvider
					value={layoutContext}
					// onPinChange={handleFocusStateChange}
					onWidgetChange={widgetUpdate}
				>
					<div className="lk-video-conference-inner">
						{!focusTrack ? (
						<div className="lk-grid-layout-wrapper">
								<GridLayout tracks={tracks.filter(x => (x.publication && x.publication.allowed) || (x.participant && x.participant.permissions && x.participant.permissions.recorder))}>
								<ParticipantTile />
							</GridLayout>
						</div>
						) : (
						<div className="lk-focus-layout-wrapper">
							<FocusLayoutContainer>
								<CarouselLayout tracks={carouselTracks}>
									<ParticipantTile />
								</CarouselLayout>
								{focusTrack && <FocusLayout trackRef={focusTrack} />}
							</FocusLayoutContainer>
						</div>
            )}
						<ControlBar controls={{ chat: true, settings: !!SettingsComponent }} />
					</div>
					<Chat
						style={{ display: widgetState.showChat ? 'grid' : 'none' }}
						messageFormatter={chatMessageFormatter}
						messageEncoder={chatMessageEncoder}
						messageDecoder={chatMessageDecoder}
					/>
					{SettingsComponent && (
						<div
							className="lk-settings-menu-modal"
							style={{ display: widgetState.showSettings ? 'block' : 'none' }}
						>
							<SettingsComponent />
						</div>
					)}
				</LayoutContextProvider>
			)}
			<RoomAudioRenderer />
			<ConnectionStateToast />
		</div>
	);
}