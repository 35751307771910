/*global ApeConfig*/

import React from 'react';
import { Select, Flex, Radio } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { usePermissions } from '../context/PermissionsContext'
const { Text } = Typography;

const ProtcolImage = ({ src }) => {
	return <img src={src} width={20} height={20} />
}

export const ProtocolDisplay = ({ token  }) => {
	//const config = ApeConfig.find(x => x.Short === token.chain.toString().toLowerCase() || x.ID === token.chain);
	return <Flex align="center" justify="center" style={{ color: '#fff', lineHeight: '24px' }}><ProtcolImage src='/img/v2.svg' /><span style={{ height: '20px'}}>{`V${token.protocol}`}</span></Flex>;
}

export const ProtocolToggle = ({ config, protocol, setProtocol }) => {
	const { isAuthenticated } = usePermissions();

	return (isAuthenticated && config ?
		<Flex vertical gap={5} style={{ margin: '0 5px 0 0' }}>
			<Text>Protocol</Text>
			<Radio.Group value={protocol} onChange={(e) => { setProtocol(e.target.value); }}>
				{(config.ApeRouters ?? []).length > 0 ? <Radio.Button key={2} value={2}>
					<Flex align="center" gap={5}>
						<ProtcolImage src='/img/v2.svg' />
						<Text>V2</Text>
					</Flex>
				</Radio.Button> : <></>}
				{(config.ApeV3Routers ?? []).length > 0 ? <Radio.Button key={3} value={3}>
					<Flex align="center" gap={5}>
						<ProtcolImage src='/img/v2.svg' />
						<Text>V3</Text>
					</Flex>
				</Radio.Button> : <></>}
			</Radio.Group>
			<Typography>
				{protocol === 3 ? "Uniswap V3 tokens come with a 1% LP fee, which is shared 50/50 between the creator and liquidity providers. These tokens are immediately listed on Uniswap, allowing for instant trading and improved liquidity options compared to V2. The fee structure supports creators while enabling seamless token management." : " Uniswap V2 tokens are deployed using a bonding curve model. They are not listed on Uniswap until they reach a $69K market cap. Tokens on V2 do not incur any LP (Liquidity Provider) fees, providing a simpler mechanism for trading and liquidity. "}
			</Typography>
		</Flex> : <></>
	);
}



export const ProtocolSelect = ({ chainId, setChainId, mobile }) => {

	const supportedChains = [
		{
			value: 0,
			label: (
				<Flex align="center" justify="center" gap={5}>
					{mobile ? <Text>All</Text> : <Text>All Networks</Text>}
				</Flex>
			)
		}
	];

	for (let i = 0; i < ApeConfig.length; i++) {
		supportedChains.push({
			value: ApeConfig[i].ID,
			disabled: !ApeConfig[i].Active,
			label: (
				<Flex key={ApeConfig[i].ID} align="center" justify="center" gap={5}>
					<ProtcolImage src={ApeConfig[i].ChainLogo} />
					{mobile ? <></> : <Text>{ApeConfig[i].Name}</Text>}
				</Flex>
			),
		});
	}

	const labelRender = ({ label }) => {
		if (label) {
			return label;
		}

		return (
			<Flex align="center" gap={5}>
				{mobile ? <Text>All</Text> : <Text>All Networks</Text>}
			</Flex>
		);
	};

	const handleChainChange = (item) => {
		setChainId(item);
	}

	return (
		<Select
			style={{ width: mobile ? '80px' : '200px' }}
			labelRender={labelRender}
			suffixIcon={<DownOutlined style={{ display: 'none' }} />}
			popupMatchSelectWidth={false}
			onChange={handleChainChange}
			value={chainId}
			options={supportedChains} />
	);
}
