/* global ApeConfig */
import React from 'react';
import { Col, Row, Flex, Select, Input, Pagination, Spin, Checkbox, Tooltip, Button, theme } from 'antd';
import { TwitterIcon, TelegramIcon, WebIcon, SearchIcon, SettingsIcon, DexScreenerIcon } from './ApeIcons';
import { ChainDisplay, ChainSelect } from '../shared/ChainSelect';
import { ProtocolDisplay } from '../shared/ProtocolDisplay';
import { TokenLogo } from './TokenLogo';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useNavigate } from "react-router-dom";
import { SocialIcon } from '../shared/SocialIcon'
import { UserIcon } from '../shared/UserIcon'
import { DownOutlined, ThunderboltFilled, ThunderboltOutlined, EyeFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import { useSockets } from '../context/SocketsContext';
import { useAnimation } from '../context/AnimationContext';
const { Text } = Typography;

export const ApeGrid = ({ items, page, setPage, sort, setSort, order, setOrder, search, setSearch, filter, setFilter, chain, setChain, pages, showPager = true }) => {
	const { mobile } = useSockets();
	const { enableAnimation, setEnableAnimation } = useAnimation();
	const [ showFilter, setShowFilter ] = React.useState(false);

	const onChangePage = (page) => {
		setPage(Math.min(pages, page - 1));
	};

	const onToggleAnimations = async () => {
		await setEnableAnimation(!enableAnimation);
		window.location.reload();
	};

	const toggleShowFilter = () => {
		setShowFilter(!showFilter);
	}

	const derrivedPage = Math.min(page, pages);

	return <>
		<Row style={{ background: 'rgb(27, 29, 40)', zIndex: 1, position: 'relative' } }>
			<Col xs={24} style={{ padding: '20px' }} >
				<Flex align="center" gap={5}>
					<Select
						value={sort}
						onChange={(value) => { setSort(value) }}
						size="small"
						style={{ width: '150px', height: '32px', background: '#282A34', border: '1px solid #282A34' }}
						suffixIcon={<DownOutlined style={{ display: 'none' }} />}
						options={[
							{ value: 0, label: 'Creation Time' },
							{ value: 1, label: 'Live Updates' },
							{ value: 2, label: 'Market Cap' },
							{ value: 3, label: 'Replies' }
						]}
					/>
					<Select
						value={order}
						onChange={(value) => { setOrder(value) }}
						size="small"
						style={{ width: '40px', height: '32px' }}
						suffixIcon={<DownOutlined style={{ display: 'none' }} />}
						options={[
							{ value: 0, label: <><span style={{ color: '#86efac' }}>↓</span>↑</> },
							{ value: 1, label: <>↓<span style={{ color: '#86efac' }}>↑</span></> }
						]}
					/>

					{mobile ? <></> :
						<>
							<ChainSelect chainId={chain} setChainId={setChain} mobile={mobile} />
							<Select
								value={filter}
								onChange={(value) => { setFilter(value) }}
								size="small"
								style={{ width: mobile ? '75px' : '150px', height: '32px' }}
								suffixIcon={<DownOutlined style={{ display: 'none' }} />}
								options={[
									{ value: 0, label: mobile ? 'All' : 'All' },
									{ value: 1, label: mobile ? 'Active' : 'Active' },
									{ value: 2, label: mobile ? 'Free' : 'Free' }
								]}
							/>
							<Tooltip title={enableAnimation ? "Disable animations" : "Enable animations"}>
								<Button type="primary"  onClick={onToggleAnimations}>
									{enableAnimation ? <ThunderboltFilled /> : <ThunderboltOutlined />}
								</Button>
							</Tooltip>
						</>
					}

					<Input size="large" maxLength={48} style={{ width: '145px', height: '32px' }} placeholder="Search" value={search} onChange={(evt) => { setSearch(evt.target.value); }} prefix={<SearchIcon size={10} />} />

					{mobile ? <Tooltip title="More Filters">
						<div>
							<SocialIcon size={16} onClick={() => toggleShowFilter()} icon={SettingsIcon} />
						</div>
					</Tooltip> : <></> }
				</Flex>
				{showFilter && mobile ? <Flex vertical style={{ width: '100%' }}>
					<h5 style={{ margin: 4 }}>Network</h5>
					<ChainSelect chainId={chain} setChainId={setChain} mobile={false} />
					<h5 style={{ margin: 4}}>Status</h5>
					<Select
						value={filter}
						onChange={(value) => { setFilter(value) }}
						size="small"
						style={{ width: '150px', height: '32px' }}
						suffixIcon={<DownOutlined style={{ display: 'none' }} />}
						options={[
							{ value: 0, label: mobile ? 'All' : 'All' },
							{ value: 1, label: mobile ? 'Active' : 'Active' },
							{ value: 2, label: mobile ? 'Free' : 'Free' }
						]}
					/>
					<Tooltip title={enableAnimation ? "Disable animations" : "Enable animations"}>
						<Button type="primary" onClick={onToggleAnimations}>
							{enableAnimation ? <ThunderboltFilled /> : <ThunderboltOutlined />}
						</Button>
					</Tooltip>
				</Flex> : <></>}
			</Col>
		</Row>
		<Row>
			{enableAnimation ?
				<TransitionGroup style={{ display: 'flex', justifyContent: "space-evenly", width: '100%', flexWrap: 'wrap' }}>
					{items.map((row, index) => {
						return (
							<CSSTransition key={`${row.id}`} timeout={300} classNames={row.animate ? "ape-slide" : "ape-fade"}>
								<Item value={row} />
							</CSSTransition>
						);
					})}
				</TransitionGroup> :
				<div style={{ display: 'flex', justifyContent: "space-evenly", width: '100%', flexWrap: 'wrap' }}>
					{items.map((row, index) => {
						return (
							<div key={`${row.id}`} timeout={300} classNames={row.animate ? "ape-slide" : "ape-fade"}>
								<Item value={row} />
							</div>
						);
					})}
				</div>
			}
		</Row>
		{showPager ?
			<Row align="center" style={{ paddingBottom: '20px' }}>
				<Pagination rootClassName="grid-pager" current={derrivedPage + 1} onChange={onChangePage} defaultPageSize={18} simple showSizeChanger={false} total={pages} />
			</Row> : <></>
		}
	</>

}

export const Item = ({ value, isKing = false }) => {
	const navigate = useNavigate();
	const { latestEvent } = useSockets();
	const nodeRef = React.useRef(null);
	const [flash, setFlash] = React.useState(false);


	React.useEffect(() => {
		if (value && latestEvent && value.id === latestEvent?.token?.id && latestEvent.message.startsWith("Bought")) {
			setFlash(true);
			setTimeout(() => setFlash(false), 100);
		}
	}, [latestEvent, value]);

	const {
		token: { textGreen, textGray9c, textBlue, textYellow },
	} = theme.useToken();

	if (!value) {
		return (
			<div style={{
				width: '340px',
				height: '180px',
				borderRadius: '18px',
				background: '#161721',
				marginBottom: '20px',
				display: 'flex',
			}}>
				<Spin size="large" style={{ margin: 'auto' }} />
			</div>
		);
	};

	const formatNumber = (value) => {
		return Intl.NumberFormat('en-US', {
			notation: "compact",
			maximumFractionDigits: 1
		}).format(value);
	};

	const lookupChain = (chain) => {
		return ApeConfig.find(x => x.Short === chain || x.ID === chain)?.Short ?? chain;
	}

	return (

		<Col
			xs={{ flex: '100%' }}
			lg={{ flex: '50%' }}
			xl={{ flex: '30%' }}
			style={{ display: 'flex', justifyContent: 'center', height: '180px', marginBottom: '20px' }}
		>
			<CSSTransition timeout={300} in={flash} nodeRef={nodeRef} classNames="ape-flash">				
					<div ref={nodeRef} style={{
				width: '340px',
				height: '180px',
				borderRadius: '18px',
				background: '#161721',
				marginBottom: '20px',
				cursor: 'pointer',
				position: 'realtive',
				border: isKing ? 'none' : value.launchDate !== null ? 'solid 1px #86EFAC' : value.isKing ? 'solid 1px #FFE814' : 'none'
				}}><a href={`/${lookupChain(value.chain)}/${value.address}`} onClick={(evt) => {
						if (!evt.shiftKey && !evt.ctrlKey) {
							evt.preventDefault();
							navigate(`/${lookupChain(value.chain)}/${value.address}`);
						}
				}}>
				<Flex justify="space-between" style={{ height: '100%', width: '100%', padding: '20px' }}>
					<Flex justify="flex-start">
						<Flex justify="space-between" vertical>
							<TokenLogo value={value} size={100} />
							<Flex justify="flex-start" style={{ marginTop: '20px' }}>
										{value.website ? <SocialIcon component={Text} icon={WebIcon} href={value.website} style={{ margin: "5px" }} /> : <></>}
										{value.telegram ? <SocialIcon component={Text} icon={TelegramIcon} href={value.telegram} style={{ margin: "5px" }} /> : <></>}
										{value.twitter ? <SocialIcon component={Text} icon={TwitterIcon} href={value.twitter} style={{ margin: "5px" }} /> : <></>}
										{value.dexPaid ? <SocialIcon component={Text} icon={DexScreenerIcon} href={`https://marketplace.dexscreener.com/order-status/${lookupChain(value.chain)}/${value.address}`} style={{ margin: "5px" }} /> : <></>}
							</Flex>
						</Flex>
						<Flex vertical style={{ marginLeft: '10px' }} >
							<Text style={{
								fontSize: '14px',
								fontWeight: 600,
								lineHeight: '20px',
								textAlign: 'left',
								color: '#fff'
							}}>{value?.symbol}</Text>
							{value?.launchDate != null ?
								<Text style={{
									fontSize: '12px',
									fontWeight: 400,
									lineHeight: '16px',
									textAlign: 'left',
									color: textYellow
								}}>{`Listed on ${ApeConfig.find(x => x.Short === value?.chain || x.ID === value?.chain).DexName}`}</Text> :
								<Text style={{
									fontSize: '12px',
									fontWeight: 400,
									lineHeight: '16px',
									textAlign: 'left',
									color: textGreen
								}}>{`Market Cap: $${formatNumber(value?.marketCap)}`}</Text>
							}
							<Text style={{
								fontSize: '12px',
								fontWeight: 400,
								lineHeight: '16px',
								textAlign: 'left',
								color: textGray9c
							}}>{`replies: ${value?.chatCount}`}</Text>
							<Text style={{
								fontSize: '14px',
								fontWeight: 500,
								lineHeight: '20px',
								textAlign: 'left',
								color: '#fff'
							}}>{value?.name}</Text>
							<Flex>
								<Text style={{
									fontSize: '12px',
									fontWeight: 400,
									lineHeight: '16px',
									textAlign: 'left',
									color: textBlue
									}}>Deployed by</Text>
								<UserIcon address={value?.creator} style={{ width: '18px', height: '18px', marginLeft: '10px' }} />
							</Flex>
						</Flex>
					</Flex>
						{ isKing == false && (value.launchDate !== null || value.isKing) ? (
								<Flex vertical justify="space-between" align="flex-end">
								{ value.launchDate !== null ?
									<img style={{ width: '40px', height: '30px' }} src="/img/logo.png" /> :
									<img style={{ width: '46px', height: '46px' }} src="/img/crown.png" />
								}
									<Flex gap={5}>
										<ProtocolDisplay token={value} />
										<ChainDisplay chain={value.chain} />
									</Flex>
							</Flex>
						) : (
							<Flex vertical justify="flex-end" align="right">
								<Flex gap={5}>
									<ProtocolDisplay token={value} />
									<ChainDisplay chain={value.chain} />
								</Flex>
							</Flex>
						)}				
						</Flex>
					</a>
					{value.isStreaming ? <div style={{
						top: '20px',
						position: 'absolute',
						width: '340px',
						display: 'flex',
						justifyContent: 'flex-end',
					}}>
						<Flex vertical>
							<div style={{ width: '50px', background: 'red', textAlign: 'center' }}>● LIVE</div>
							<div style={{ width: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><EyeFilled /> {value.streamViewers}</div>
						</Flex>
					</div> : <></>}
				</div>

			</CSSTransition>
		</Col>
	);
}

